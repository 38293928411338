import React, { useState } from "react";
import "../css/Projects.css";
import { useDarkMode } from "./DarkModeContext";
import { Link } from "react-router-dom";

import reactJsDark from "../assets/reactjsdark.png";
import tailwindCssDark from "../assets/tailwinddark.png";
import expogoDark from "../assets/expogodark.png";
import appwriteDark from "../assets/appwritedark.png";
import dockerDark from "../assets/dockerdark.png";
import gitDark from "../assets/gitdark.png";
import knexjsDark from "../assets/knexjsdark.png";
import postgresqlDark from "../assets/postgresqldark.png";
import gsapDark from "../assets/gsapdark.png";

import reactJsLight from "../assets/reactjslight.png";
import tailwindCssLight from "../assets/tailwindlight.png";
import expogoLight from "../assets/expogolight.png";
import appwriteLight from "../assets/appwritelight.png";
import dockerLight from "../assets/dockerlight.png";
import gitLight from "../assets/gitlight.png";
import knexjsLight from "../assets/knexjslight.png";
import postgresqlLight from "../assets/postgresqllight.png";
import gsapLight from "../assets/gsaplight.png";

const Projects = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const [backgroundVideo, setBackgroundVideo] = useState("");
  const projects = [
    {
      id: "01",
      title: "Nutriflex",
      description:
        "NutriFlex, an innovative dietary application aimed at transforming personalized nutrition management, is the result of my efforts to develop a user-friendly application using <b>React Native</b>, <b>Native Wind</b>, <b>Expo</b>, and <b>Appwrite</b>. This application allows people to effortlessly track their nutritional intake, explore healthy recipes, and track their progress toward fitness goals. NutriFlex is designed to provide a seamless user experience, leveraging modern technologies to help users manage their nutrition effectively.",
      videoUrl: "/assets/NutriFlex_Showreel_2.0.mp4",
      logos: [
        isDarkMode ? reactJsLight : reactJsDark,
        isDarkMode ? tailwindCssLight : tailwindCssDark,
        isDarkMode ? expogoLight : expogoDark,
        isDarkMode ? appwriteLight : appwriteDark,
      ],
    },
    {
      id: "02",
      title: "School Forum",
      description:
        "The Student Forum API project consists of two main components: client and server. The client is a <b>React.js</b> application that provides a responsive and interactive user interface. The server is built with <b>Express.js</b> and uses a <b>PostgreSQL</b> database managed by <b>Knex.js</b>, all orchestrated with <b>Docker</b>. The server supports <b>integration</b>, <b>unit</b>, and <b>end-to-end testing</b>, and includes a <b>CI/CD pipeline</b> for automated testing and deployment. This project showcases the use of modern web technologies to create a functional and user-friendly forum.",
      videoUrl: "/assets/student-forum.mp4",
      logos: [
        isDarkMode ? reactJsLight : reactJsDark,
        isDarkMode ? tailwindCssLight : tailwindCssDark,
        isDarkMode ? knexjsLight : knexjsDark,
        isDarkMode ? postgresqlLight : postgresqlDark,
        isDarkMode ? dockerLight : dockerDark,
        isDarkMode ? gitLight : gitDark,
      ],
    },
    {
      id: "03",
      title: "Stock Dashboard",
      description:
        "Created using <b>React.js</b>, is a responsive and interactive tool for real-time stock market information. Styled with <b>Tailwind CSS</b>, it presents a modern and user-friendly interface. The dashboard integrates <b>Finnhub API</b> for the latest stock prices, ensuring up-to-date data. Interactive charts, made with <b>Rechart</b>, aid in understanding market trends. Developed over 2 weeks with a total effort of approximately 33 hours, this project combines multiple technologies to deliver a practical and visually appealing web application. The focus was on managing dynamic data with <b>React.js</b> and showcasing stock trends in an easy-to-understand format, making it a valuable tool for anyone interested in the stock market.",
      videoUrl: "/assets/stockdashboard.mp4",
      logos: [
        isDarkMode ? reactJsLight : reactJsDark,
        isDarkMode ? tailwindCssLight : tailwindCssDark,
      ],
    },
    {
      id: "04",
      title: "Voronoi Stippling Effect",
      description:
        "This website showcases artistic web design using <b>React JS</b>. It features a voronoi stippling effect on a Marcus Aurelius statue for the landing page background. The site includes a <b>GSAP</b>-powered animation where a circle bounces and expands to fill the screen. The landing page is followed by a minimalist section on Marcus Aurelius. The color scheme and design align with my portfolio's themes, creating a consistent user experience. This project demonstrates my ability to combine complex visual elements with informative content using modern web technologies.",
      videoUrl: "/assets/marcusaurelius.mp4",
      logos: [
        isDarkMode ? reactJsLight : reactJsDark,
        isDarkMode ? gsapLight : gsapDark,
      ],
    },
  ];

  const handleHover = (videoFileName) => {
    setBackgroundVideo(process.env.PUBLIC_URL + videoFileName);
  };
  return (
    <div className={`parent ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="div1" onClick={toggleDarkMode}>
        {" "}
      </div>
      <div className="div2">
        <svg
          className="icon"
          xmlns="http://www.w3.org/2000/svg"
          width="81.866"
          height="45.088"
          viewBox="0 0 81.866 45.088"
        >
          <g id="zerzerez" transform="translate(-495 591.169)">
            <path
              id="Path_5"
              data-name="Path 5"
              d="M495-589.869v1.248h7.765c8.373,0,9.489.082,11.985.9a20.209,20.209,0,0,1,11.443,10.064,19.324,19.324,0,0,1-1.051,19.964,21.514,21.514,0,0,1-10.8,8.291c-2.528.772-3.53.837-11.656.837H495v2.463h8.192c8.882,0,9.818-.066,12.313-.936a24.184,24.184,0,0,0,13.085-11.919,21.337,21.337,0,0,0-.033-19.405,23.738,23.738,0,0,0-6.945-8.553A26.283,26.283,0,0,0,515.6-590.1c-2.315-.8-2.939-.854-12.018-.936L495-591.1Z"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M791.955-590.495a22.69,22.69,0,0,0-17.911,13.856,22.233,22.233,0,0,0,5.369,24.626,22.128,22.128,0,0,0,16.056,6.271,21.209,21.209,0,0,0,8.8-2,19.177,19.177,0,0,0,4.006-2.512,3.5,3.5,0,0,0-.8-.837l-.821-.673-1.166.755a19.081,19.081,0,0,1-5.91,2.38,28.131,28.131,0,0,1-8.849.1,19.973,19.973,0,0,1-9.933-5.319,20.142,20.142,0,0,1,.1-28.714,19.485,19.485,0,0,1,10.573-5.385,24.38,24.38,0,0,1,8.849.328,19.248,19.248,0,0,1,5.336,2.282,14.272,14.272,0,0,0,1.478.9,5.409,5.409,0,0,0,.8-.722l.706-.706-.722-.575a20.857,20.857,0,0,0-8.258-3.76A31.833,31.833,0,0,0,791.955-590.495Z"
              transform="translate(-231.844 -0.408)"
              stroke="#f5f5f5"
              stroke-width="0.1"
            />
          </g>
        </svg>
      </div>
      <div className="div3">
        <video
          src={backgroundVideo}
          autoPlay
          loop
          muted
          className={`video-background ${backgroundVideo ? "show" : ""}`}
        />
        <div className="video-gradient"></div>
      </div>

      <div className="div4">
        <h1>Dmitr Cambur</h1>
        <h2>Full Stack Web Developer</h2>
      </div>
      <div className="div5">
        {projects.map((project) => (
          <article
            className="episode"
            key={project.id}
            onMouseEnter={() => handleHover(project.videoUrl)}
            onMouseLeave={() => handleHover("")}
          >
            <div className="episode__number">{project.id}</div>
            <div className="episode__content">
              <div className="title">{project.title}</div>
              <div className="story">
                <p dangerouslySetInnerHTML={{ __html: project.description }} />
                <div className="logos">
                  {project.logos.map((logo, index) => (
                    <img
                      key={index}
                      src={logo}
                      alt="Technology logo"
                      className="tech-logo"
                    />
                  ))}
                </div>
              </div>
            </div>
          </article>
        ))}
      </div>
      <div className="div6">
        <ul className="portfolio-list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>

      <div className="div7"></div>
      <div className="div8"></div>

      <div className="div9"></div>
    </div>
  );
};

export default Projects;
